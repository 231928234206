
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import VerticalSteps from "../../components/VerticalSteps";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import ReactPlayer from 'react-player'
import Stack from '@mui/material/Stack';
import Button from "@material-ui/core/Button";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import Form from "../../components/NetlifyForm";
export const ggbParameters = {
  // material_id: "",
  // width: "100%",
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Container = makeShortcode("Container");
const ImageCard = makeShortcode("ImageCard");
const ContentMedia = makeShortcode("ContentMedia");
const Text9 = makeShortcode("Text9");
const layoutProps = {
  ggbParameters
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`delta, volatility and time`}</h1>

    {
      /* https://storage.googleapis.com/chapters-ifm/testfolder/basics/long_call_2d_3d_delta1.json */
    }
    <Container mdxType="Container">
      <p>{`Delta measures the change in the option price due to a change in the asset price.`}</p>
      <p>{`Gamma measures the change in the option delta due to a change in the asset price.`}</p>
      <br />
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/delta_surface_1.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>
    <Container mdxType="Container">
  <ContentMedia title="See Delta of long and short call" mdxType="ContentMedia">
  <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/delta_call.png
" mdxType="ImageCard" />
  </ContentMedia>
  <ContentMedia title="See Delta of long and short put" mdxType="ContentMedia">
  <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/delta_put.png
" mdxType="ImageCard" />
  </ContentMedia>
    </Container>
    <br />
    <h1>{`Impact of volatility on delta`}</h1>
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/delta_surface_2.png
" mdxType="ImageCard" />
    <br />
    <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/PUTS/delta_surface_3.png
" mdxType="ImageCard" />
    <br />
    <h1>{`Interactive`}</h1>
    <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/2022/BASICS/gamma_3d_2022_may.json" ggbParameters={ggbParameters} appId="appId1Gamma" email="pavanmirla@gmail.com" data={[{
      component: <Text9 mdxType="Text9" />,
      button: "text14"
    }, {
      component: <Text9 mdxType="Text9" />,
      button: "text9"
    }]} mdxType="GeogebraDraw" /> 
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;