
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import YouTube from "react-youtube";
import GeogebraDraw from "../../components/GeogebraDraw";
import IfPaidUser from "../../components/IfPaidUser";
import "../../markdown-styles/styles.css";
import { useState } from 'react'
import Alert from '@mui/material/Alert';
import data from '../../data/tulip_spot_trade.json';
import GeogebraComponent from "../../components/GeogebraComponent";
import VideoSection from "../../components/VideoSection";
import Content from "../../components/Content";
import Container from "../../components/Layouts/Container";
import ContentTextBoxes from "../../components/Layouts/ContentTextBoxes";
import Link from '@mui/material/Link';
import ReactPlayer from 'react-player'
export const opts = {
  height: "390",
  width: "640",
  playerVars: {
    // https://developers.google.com/youtube/player_parameters
    autoplay: 1,
    start: 5,
    end: 24
  }
};
export const short_position = data.data;
export const short_position_params = {
  // material_id: "",
  width: 1800,
  height: 900,
  borderColor: "#1E1E1E",
  enableShiftDragZoom: false,
  ggbBase64: short_position
};
export const meta = {
  title: " This is my Title2",
  chapter: "Chapter1",
  ispublished: true,
  date: "21 dec 2021",
  author: "Pavan Kumaar Mirla"
};
export const Highlight = ({
  children,
  color
}) => <span style={{
  backgroundColor: color,
  borderRadius: '2px',
  color: '#fff',
  padding: '0.2rem'
}}>
    {children}
  </span>;
export const ggbParameters = {
  // material_id: "",
  // width: 1800,
  // height: 950,
  borderColor: "#1E1e1E",
  enableShiftDragZoom: false,
  showToolBar: false
};
export const Box1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: "500px",
  height: "600px"
}}>
    <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/call_intro/call_intro_long_short.mp4"} mdxType="VideoSection" /> 
  </div>;
export const Player = () => {
  const ref = React.createRef();
  return <div>
      <ReactPlayer ref={ref} url='http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4' playing controls config={{
      file: {
        attributes: {
          id: 'audio-element'
        }
      }
    }} width='320px' height='180px' mdxType="ReactPlayer" />
      <button onClick={() => ref.current.seekTo(10)}>Seek to 00:10</button>
    </div>;
};
export function App() {
  useEffect(() => {
    this.p.seekTo(18.7);
  }, []);
  return <div className="App">
      <ReactPlayer ref={p => {
      this.p = p;
    }} url="//s3.envoy.rocks/bothrs/goud-design-sprint/goud/LhgEcS_GOUD+PROTOTYPE+SHOWCASE.mp4" className="react-player" playing controls width="100%" height="100%" mdxType="ReactPlayer" />
      <button onClick={() => this.p.seekTo(0.9999999)}>Seek to end</button>
      <button onClick={() => this.p.seekTo(0.999)}>
        Seek to end (works in Safari)
      </button>
      <button onClick={() => {
      console.log(this.p.getDuration());
      this.p.seekTo(this.p.getDuration());
    }}>
        Seek to end (with getDuration())
      </button>
      <button onClick={() => this.p.seekTo(12.7)}>Seek to 12.7</button>
      <button onClick={() => this.p.seekTo(42.65)}>Seek to 42.65</button>
    </div>;
}
export const Video1 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato',
  width: "600px",
  height: "400px"
}}>
    <ReactPlayer controls width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/call_intro/call_intro_long_short.mp4' mdxType="ReactPlayer" />
  </div>;
export const Box2 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <ReactPlayer controls width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/videos/Theta_video_drive.mp4' mdxType="ReactPlayer" />
  </div>;
export const DefaultBox = () => <div style={{
  padding: 20,
  backgroundColor: 'yellow'
}} />;
export const Text4 = () => <div style={{
  padding: 20,
  backgroundColor: 'tomato'
}}>
    <YouTube videoId="Wt827RAJHaY" opts={opts} mdxType="YouTube" />
  </div>;
export const Video2 = () => <>
  <div style={{
    padding: 20,
    backgroundColor: 'tomato',
    width: "600px",
    height: "400px"
  }}>
    <ReactPlayer controls="true" width="100%" height="100%" url='https://storage.googleapis.com/chapters-ifm/testfolder/2022/short_condor/video2_ic_1.mp4' mdxType="ReactPlayer" />
  </div>
  </>;
export const componetArray = [<DefaultBox mdxType="DefaultBox" />, <Box1 mdxType="Box1" />, <Box2 mdxType="Box2" />];
export const indexArray = {
  button1: 1,
  text14: 1
};
export const items1 = [{
  title: 'Why would someone buy a call option? ',
  subtitle: "A trader buys a call based on the expectation of share price appreciation. This locks in a purchase price. If share price does not increase, the maximum loss will be limited to the cost of the call "
}, {
  title: 'Describe intrinsic value of a call option',
  subtitle: "A call option is in-the-money if share price is higher than the call's strike price. The intrinsic value is the amount by which the stock is above the strike price."
}, {
  title: 'What does it mean to be out-of-the-money for a call?',
  subtitle: "For a call, out-of-the-money means that share price is less than the strike price. If this occurs at expiration, the call will be worthless."
}];
export const items2 = [{
  title: 'Who is a typical call seller? ',
  subtitle: "Call sellers typically own stocks that they can deliver if the call owner exercises the right to buy the asset.The volume of short calls in spreads, straddles, strangles and naked calls might far exceed covered writes, particularly because the cost of a spread is so much less than the margin of a covered call "
}, {
  title: 'What is the difference between a covered call and a naked call?',
  subtitle: "If the call seller owns the asset, it is known as a covered call. If the call is sold without holding the underlying asset, it is a naked call "
}, {
  title: 'How do call sellers generate income?',
  subtitle: "Option sellers receive a premium for selling options, generating income. The premium is retained if the stock remains below the strike price. The trader could be selling calls as part of a combination trade - such as a strangle, call spread, etc. "
}, {
  title: "What is difference between assignment and exercise?",
  subtitle: "If a call holder exercises his right, he buys the shares at the strike price. The assigned call seller must deliver 100 shares. The broker will deliver them to the buyer and transfer the cash to the seller. If the call seller does not own the shares, he will end up short 100 shares."
}];
export const exercise_items = [{
  title: 'What is meant by exercising a call option?',
  subtitle: "Action taken by call holder to buy 100 shares of the stock for a specific price is called exercising a call option.Exercising a long call entitles you to purchase 100 shares at 100 times the strike price."
}, {
  title: 'When will Call holder exercise his call?',
  subtitle: "The call holder exercises when there is no time premium remaining and he wants to acquire the stock. If the premium is higher than the price at which the call was purchased, he's likely to sell to close the call"
}, {
  title: 'Can the call holder sell the call before it expires?',
  subtitle: 'The owner of a call can sell at any time.'
}, {
  title: 'Who coordinates the exercise of options and delivery of stock?',
  subtitle: "The option clearing corporation coordinate exercise and assignment of options. The brokers coordinate delivery of cash and stock"
}, {
  title: 'What is automatic exercise?',
  subtitle: "In-the-money (ITM) options at expiration will be automatically exercised by the Option Clearing Corp (OCC) whether they are long or short. This is called Exercise by Exception. For equity options, you will end up with a long or short position in the underlying (index options are cash settled). The owner of an option can avoid automatic exercise if he submits a do not exercise order to his broker."
}];
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  opts,
short_position_params,
meta,
Highlight,
ggbParameters,
Box1,
Player,
App,
Video1,
Box2,
DefaultBox,
Text4,
Video2,
componetArray,
indexArray,
items1,
items2,
exercise_items
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

















    <h1>{`what is a call option?`}</h1>
    <Container mdxType="Container">
      <br />
      <br />
      <p>{` A call option gives the owner the right, but not the obligation, to buy the underlying asset
at a specified price any time until a specified date known as expiration. `}</p>
      <p>{`The call buyer can:`}</p>
      <p>{`1 : Speculate on the upward direction of stock`}</p>
      <p>{`2 : Earn a credit by selling the call `}</p>
      <p>{`3 : To hedge a short position in underlying or another option`}</p>
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/call-with_logo.png
" mdxType="ImageCard" />
      <br />
      <br />
    </Container>
    <h1>{`Owning a call option is like owning a coupon`}</h1>
    <Container mdxType="Container">
      <VideoSection video={"https://storage.googleapis.com/chapters-ifm/testfolder/2022/options_intro/pzza_video_payoff_goal_oriented.mp4"} mdxType="VideoSection" />
    </Container>
    <h1>{`call intrinsic value and profit graphs`}</h1>

    <Container mdxType="Container">
  <Content items={items1} title={"Call Buyer"} mdxType="Content" />
    </Container>

    <Container mdxType="Container">
  <Content items={items2} title={"Call Seller"} mdxType="Content" />
    </Container>
    <h1>{`Interactive 1`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/call_intro/risk_graph_call_1.json" ggbParameters={ggbParameters} appId="parity_grap_call" email="pavanmirla@gmail.com" data={[]} mdxType="GeogebraDraw" />
    <h1>{`DEFINE CALL EXERCISE`}</h1>
    <Container mdxType="Container">
      <br />
      <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/call_intro/call_possible_outcomes.png" mdxType="ImageCard" />
      <br />
    </Container>

    <Container mdxType="Container">
  <Content items={exercise_items} title={""} mdxType="Content" />
    </Container>
    <br />
    <br />
    <h1>{`Interactive 2`}</h1>
  <GeogebraDraw jsonUrl="https://storage.googleapis.com/chapters-ifm/testfolder/BOOK/call_intro/call_short_long_3.json" ggbParameters={ggbParameters} appId="second_app" email="pavanmirla@gmail.com" data={[{
      component: <Video1 mdxType="Video1" />,
      button: "video1"
    }, {
      component: <Player mdxType="Player" />,
      button: "video2"
    }]} mdxType="GeogebraDraw" />
    <br />
    <br />
    <h1>{`Related Topics`}</h1>
    <br />
    <br />
    <br />
    <br />
    <h1>{`1. CALL OPTION PRICE SENSITIVITIES`}</h1>
    <Container mdxType="Container">
      <Link href="http://options.21ifm.com/call_greeks_long" target="_blank" mdxType="Link">
        <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/greeks_intro_revised.gif
" mdxType="ImageCard" />
      </Link>
    </Container>
    <h1>{`2. SHORT CALL MARGIN REQUIREMENTS`}</h1>
    <Container mdxType="Container">
      <Link href="http://options.21ifm.com/margin_call_seller" target="_blank" mdxType="Link">
        <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/margin_call_call_trailer.gif
" mdxType="ImageCard" />
      </Link>
    </Container>
    <h1>{`3. COVERED CALL`}</h1>
    <Container mdxType="Container">
      <Link href="http://options.21ifm.com/covered_call" target="_blank" mdxType="Link">
        <ImageCard img="https://storage.googleapis.com/chapters-ifm/testfolder/THEOMASTER/CALLS_PUTS/covered_call_click.gif
" mdxType="ImageCard" />
      </Link>
    </Container>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;